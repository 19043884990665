import { useMemo } from 'react';
import { attachClientImpressionHandler, trackClientContentAction } from './client-event-tracking';
import { isNews } from '../../utils/isNews';

/**
 * Returns function for tracking events
 *
 * Import at the top of the component/file as:
 * const trackingFn = useCommentsCtaButtonTracking(buzz)
 *
 * Pass the tracking fn to the comments component via react props
 * eventData will be provided by the component when it fires the event
 * Reference _track function in https://github.com/buzzfeed/mono/blob/master/packages/frontend/react-components/lib/components/CommentsCtaButton
 *
 * @returns {Function}
 */
export function useCommentsCtaButtonTracking(buzz, data = {}) {
  return useMemo(
    () => ({
      trackClick: () => {
        if (!buzz || !buzz.id) {
          return;
        }
        trackClientContentAction(buzz, {
          item_name: 'comment-cta',
          item_type: 'button',
          action_value: 'comment-cta',
          action_type: 'navigate',
          unit_name: buzz.id,
          unit_type: 'buzz_head',
          ...data
        });
      },
      trackImpression: element => {
        if (!isNews(buzz)) {
          return;
        }
        attachClientImpressionHandler(
          element,
          buzz,
          {
            unit_type: 'buzz_head',
            unit_name: buzz.id,
            item_name: 'comment-cta',
            item_type: 'button',
            target_content_type: 'buzz',
            target_content_id: buzz.id,
            ...data
          }
        );
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buzz]
  );
}
