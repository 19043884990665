import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';
import {
  image_service_url as imageRoot,
  destinations,
  CLUSTER
} from '../../constants';
import CommentsContext from '../../contexts/comments';
import DestinationContext from '../../contexts/destination';
import { isAddYours } from '../../utils/isAddYours';
import { isDiscussionPage } from '../../utils/isDiscussionPage';
import { isNews } from '../../utils/isNews';
import dynamicImport from '../../utils/dynamic-import';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { useCommentsTracking } from '../../hooks/analytics/useCommentsTracking';
import { useCommentsCtaButtonTracking } from '../../hooks/analytics/useCommentsCtaButtonTracking';

const Comments = dynamicImport(
  () =>
    import(
      /* webpackChunkName: "comments" */ '@buzzfeed/react-components/dist/module/lib/components/Comments'
    ),
  { ssr: false }
);
const CommentsCtaButton = dynamicImport(
  () =>
    import(
      /* webpackChunkName: "comments-cta-button" */ '@buzzfeed/react-components/dist/module/lib/components/CommentsCtaButton'
    ),
  { ssr: false }
);

const CondensedCommentsWrapper = dynamicImport(
  () =>
    import(
      /* webpackChunkName: "condensed-comments" */ '../CondensedCommentsWrapper'
    ),
  { ssr: false }
);

function CommentsWrapper({
  buzz,
  commentCount,
  firstPage,
  repliesFirstPage,
  perPage,
  onCommentsLoaded,
}) {
  const { base_url: apiRoot, destination } = useContext(DestinationContext);
  const {
    isCommentsPanelOpen,
    toggleCommentsPanel,
  } = useContext(CommentsContext);
  const condensedComments = !isDiscussionPage(buzz) && !buzz.isCommentsPage && buzz.classification.edition !== 'Japan';
  const { base_url: bfBaseUrl } = destinations['buzzfeed'];
  const commentsTrackingFn = useCommentsTracking(buzz);
  const commentsBottomCtaButtonTrackingFn = useCommentsCtaButtonTracking(buzz, { unit_type: 'buzz_bottom' });
  const loginRedirectUrl = isNews(buzz)
    ? `${apiRoot}/auth/sso/signin?redirect=${buzz.canonical_url}`
    : undefined;
  const locale = buzz.language || 'en';

  const onCloseComments = useCallback(() => toggleCommentsPanel(false), [toggleCommentsPanel]);
  const onOpenComments = useCallback(() => toggleCommentsPanel(true), [toggleCommentsPanel]);

  const commentsApiRoot = CLUSTER === 'dev' ? bfBaseUrl : apiRoot;
  const comments = <Comments
    apiRoot={commentsApiRoot}
    profileRoot={bfBaseUrl}
    imageRoot={imageRoot}
    buzzId={+buzz.id}
    firstPage={firstPage}
    repliesFirstPage={repliesFirstPage}
    perPage={perPage}
    track={commentsTrackingFn}
    locale={locale}
    loginRedirectUrl={loginRedirectUrl}
    onLoaded={onCommentsLoaded}
    allowImages={isAddYours(buzz)}
    className={classNames({
      'themeNews': isNews(buzz),
      'condensedComments': condensedComments,
      'discussionPage': isDiscussionPage(buzz),
    })}
    showCloseButton={condensedComments}
    onCloseButtonClick={onCloseComments}
    onOpenComments={onOpenComments}
  />;

  return(
    <>
      {!condensedComments && (
        <div id="comments" className={styles.comments}>
          {comments}
        </div>
      )}
      {condensedComments && (
        <>
          {commentCount >= 0 && (
            <div className={styles.commentsHeader}>
              {commentCount < 5 ? '' : commentCount} Comments
            </div>
          )}
          <CommentsCtaButton
            commentCount={commentCount}
            track={commentsBottomCtaButtonTrackingFn}
            locale={locale}
            destination={destination}
            onCtaClick={() => toggleCommentsPanel(true)}
            disabled={isCommentsPanelOpen}
            customClassName={classNames(styles.commentsViewButton, {
              [styles['themeNews']]: isNews(buzz),
            })}
          />
        </>
      )}
      {condensedComments && <CondensedCommentsWrapper
        buzz={buzz}
        isCommentsPanelOpen={isCommentsPanelOpen}>
          {comments}
      </CondensedCommentsWrapper>}
    </>
  );
}

CommentsWrapper.propTypes = {
  buzz: PropTypes.object,
  firstPage: PropTypes.number,
  repliesFirstPage: PropTypes.number,
  perPage: PropTypes.number,
}

export default CommentsWrapper;
