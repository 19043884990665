import { useContext, useMemo } from 'react';
import {
  trackClientContentAction,
  trackClientInternalLink,
  trackClientExternalLink,
  attachClientImpressionHandler,
} from './client-event-tracking';
import DestinationContext from '../../contexts/destination';
import { isNews } from '../../utils/isNews';

/**
 * Returns a set of functions for tracking events
 *
 * Import at the top of the component/file as:
 * const trackingFn = useCommentsTracking(buzz)
 *
 * Pass the tracking fn to the comments component via react props
 * eventData will be provided by the component when it fires the event
 * Reference https://github.com/buzzfeed/mono/blob/master/packages/frontend/react-components/lib/components/Comments/Comments.md
 *
 * @returns {Function}
 */
export function useCommentsTracking(buzz) {
  const { destination } = useContext(DestinationContext);
  return useMemo(
    () => ({
      internalLink: eventData => {
        if (eventData.type && eventData.type === 'login') {
          trackClientInternalLink(buzz, {
            item_name: 'sign_in_to_comment',
            item_type: 'text',
            subunit_name: 'comments',
            subunit_type: 'component',
            unit_name: buzz.id,
            unit_type: 'buzz_bottom',
            target_content_type: 'auth',
            target_content_id: 'sign_in',
          });
        }
      },
      externalLink: eventData => {
        const { type, ...cetData } = eventData || {};
        if (type === 'signin_modal') {
          trackClientExternalLink(buzz, cetData);
          return;
        }
      },
      contentAction: eventData => {
        if (!buzz || !buzz.id) {
          return;
        }

        const { type, ...cetData } = eventData || {};
        if (type === 'signin_modal') {
          trackClientContentAction(buzz, {
            unit_name: buzz.id,
            unit_type: 'buzz_bottom',
            subunit_name: 'comments',
            subunit_type: 'component',
            item_name: 'sign_in_to_comment',
            item_type: 'text',
            ...cetData,
          });
          return;
        }

        let actionType = 'comment';
        let actionValue = String(eventData.comment_id) || null;
        let itemName = 'comment';
        let itemType = 'submission';
        if (eventData.parent_id) {
          actionType = 'reply';
          itemName = String(eventData.parent_id);
          itemType = 'comment';
        } else if (eventData.form === 'image') {
          actionType = 'upload';
          actionValue = eventData.image_url;
          itemName = 'image';
        }

        if (eventData.type === 'reaction') {
          actionType = 'react';
          actionValue = eventData.form;
        }

        trackClientContentAction(buzz, {
          action_type: eventData.actionType ? eventData.actionType : actionType,
          action_value: eventData.actionValue
            ? eventData.actionValue
            : actionValue,
          item_name: eventData.itemName ? eventData.itemName : itemName,
          item_type: eventData.itemType ? eventData.itemType : itemType,
          subunit_name: 'comments',
          subunit_type: 'component',
          unit_name: buzz.id,
          unit_type: 'buzz_bottom',
        });
      },
      bylineLink: ({ type, id, link }) => {
        if (type && type === 'byline') {
          const bylineTrackData = {
            item_name: 'byline',
            item_type: 'profile',
            subunit_name: 'comments',
            subunit_type: 'component',
            unit_name: buzz.id,
            unit_type: 'buzz_bottom',
          };
          if (isNews(buzz)) {
            trackClientExternalLink(buzz, {
              ...bylineTrackData,
              target_content_url: link,
            });
          } else {
            trackClientInternalLink(buzz, {
              ...bylineTrackData,
              target_content_type: 'user',
              target_content_id: id,
            });
          }
        }
      },
      impressionUnit: ({ element, type }) => {
        if (type === 'loadMore' && isNews(buzz)) {
          attachClientImpressionHandler(
            element,
            buzz,
            {
              unit_name: buzz.id,
              unit_type: 'buzz_bottom',
              subunit_name: 'comments',
              subunit_type: 'component',
              item_type: 'button',
              item_name: 'load_more',
              target_content_type: 'buzz',
              target_content_id: buzz.id
            }
          );
        }
      },
    }),
    [buzz, destination]
  );
}
